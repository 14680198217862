<template>
  <!-- chat area -->
  <div class="col-xl-12 col-lg-12">
    <div class="border-bottom border-light">
      <div class="media py-1">
        <a href="javascript:void(0)" class="mr-2 mt-2"
           @click="onBackPressed"
        ><i class="fa fa-arrow-left fa-1x"></i></a>
        <b-avatar size="md" variant="dark" class="text-light mr-1" style="cursor: pointer" @click="onBackPressed"
        ><span class="">{{ driver.name | initials }}</span></b-avatar
        >
        <div class="media-body">
          <h5 class="mt-0 mb-0 font-15">
            <router-link to="/contacts/profile" class="text-reset">
              {{driver.name}} {{driver['other_names']}}
            </router-link>
          </h5>
          <p class="mt-1 mb-0 text-muted font-12">
<!--            <small class="mdi mdi-circle text-success"></small> -->
            {{driver['phone']}}
          </p>
        </div>

        <div
            class="card shadow-none border text-left"
        >
          <div class="p-2">
            <div class="row align-items-center">
              <div class="col-auto">
                <div class="avatar-sm">
                              <span class="avatar-title bg-primary rounded"
                              >IMG</span
                              >
                </div>
              </div>
              <div class="col pl-0">
                <p class="mb-0">{{file['file_title']}} uploaded on</p>
                <a
                    href="javascript:void(0);"
                    class="text-muted font-weight-medium"
                >{{file['createdAt']}}</a
                >
              </div>
              <div class="col-auto">
                <!-- Button -->
                <a
                    href="javascript:void(0);"
                    class="btn btn-link btn-lg text-muted"
                >
                  <i class="dripicons-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="pt-4">
      <div data-simplebar style="max-height: 460px" id="scrollID">
        <ul class="conversation-list chat-app-conversation" ref="chatContent"  >
          <li
              class="clearfix"
              v-for="(data, index) in chatMessagesData"
              :key="index"
              :class="{ odd: data.sentBy === 'admin' }"
          >
            <div class="chat-avatar">
              <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer" @click="onBackPressed"
              >
                <template v-if="data.sentBy === 'admin'">
                  <span class="">{{ admin.name | initials }}</span>
                </template>
                <template v-else>
                  <span class="">{{ driver.name | initials }}</span>
                </template>


              </b-avatar
              >
            </div>
            <div class="conversation-text">
              <div class="ctext-wrap">
                <p><i>{{ data.message }}</i></p>
                <small>{{ formatTimestamp(data.sentAt) }}</small>
              </div>
              <div
                  class="card mt-2 mb-1 shadow-none border text-left"
                  v-if="data.file === true"
              >
                <!--                <div class="p-2">-->
                <!--                  <div class="row align-items-center">-->
                <!--                    <div class="col-auto">-->
                <!--                      <div class="avatar-sm">-->
                <!--                              <span class="avatar-title bg-primary rounded"-->
                <!--                              >PDF</span-->
                <!--                              >-->
                <!--                      </div>-->
                <!--                    </div>-->
                <!--                    <div class="col pl-0">-->
                <!--                      <a-->
                <!--                          href="javascript:void(0);"-->
                <!--                          class="text-muted font-weight-medium"-->
                <!--                      >minton-presentation.pdf</a-->
                <!--                      >-->
                <!--                      <p class="mb-0">2.3 MB</p>-->
                <!--                    </div>-->
                <!--                    <div class="col-auto">-->
                <!--                      &lt;!&ndash; Button &ndash;&gt;-->
                <!--                      <a-->
                <!--                          href="javascript:void(0);"-->
                <!--                          class="btn btn-link btn-lg text-muted"-->
                <!--                      >-->
                <!--                        <i class="dripicons-download"></i>-->
                <!--                      </a>-->
                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
<!--            <b-dropdown-->
<!--                class="conversation-actions"-->
<!--                toggle-class="btn-sm btn-link text-reset p-0"-->
<!--                variant="black"-->
<!--                right-->
<!--            >-->
<!--              <template v-slot:button-content>-->
<!--                <i class="mdi mdi-dots-vertical font-18"></i>-->
<!--              </template>-->
<!--              <a class="dropdown-item" href="#">Copy Message</a>-->
<!--              <a class="dropdown-item" href="#">Edit</a>-->
<!--              <a class="dropdown-item" href="#">Delete</a>-->
<!--            </b-dropdown>-->
          </li>
        </ul>
      </div>

<!--    Button to send message  -->
      <div class="row">
        <div class="col">
          <div class="mt-2 bg-light p-3 rounded">
            <form
                class="needs-validation"
                @submit.prevent=""
                name="chat-form"
                id="chat-form"
            >
              <div class="row">
                <div class="col mb-2 mb-sm-0">
                  <input
                      type="text"
                      v-model="form.message"
                      class="form-control border-0"
                      placeholder="Enter your text"
                  />
                  <div
                      v-if="submitted && $v.form.message.$error"
                      class="invalid-feedback"
                  >
                          <span v-if="!$v.form.message.required"
                          >Please enter your message</span
                          >
                  </div>
                </div>
                <div class="col-sm-auto">
                  <div class="btn-group">
<!--                    <a href="#" class="btn btn-light">-->
<!--                      <i class="fe-paperclip"></i>-->
<!--                    </a>-->
                    <button
                        type="submit"
                        @click="sendMessage"
                        class="btn btn-success chat-send btn-block"
                    >
                      <i class="fe-send"></i>
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row-->
            </form>
          </div>
        </div>
        <!-- end col-->
      </div>
    </div>
    <!-- end card -->
  </div>
  <!-- end chat area-->
</template>

<script>
import {getDatabase, ref, set, onValue, push, update, get} from "firebase/database";
import moment from 'moment';
import {chatItem, chatUserItem} from "../../../../utils/functions";

export default {
  name: "chat-on-receipts",
  props: ['driver', 'file'],
  data(){
    return {
      chatMessagesData: [],
      submitted: false,
      form: {
        message: "",
      },
      username: "Designer",
      chatUser: this.driver
    }
  },
  computed:{
    admin() {
      return this.$store.getters.GET_USER_INFO
    },
  },
  methods: {
    onBackPressed(){
      this.$emit('onBackPressed');
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = document.querySelector('#scrollID .simplebar-content-wrapper');
        if(!container){
          return;
        }
        container.scrollTo({ top: 10000000000000000000000, behavior: "smooth" });
      })
    },
    fetchChats(){
      const db = getDatabase();
      const chatRef = ref(db, 'chats/' + this.driver.id);
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();

        console.log('snapshot data', data)
        if(!data){
          return;
        }


        let arr = [];

        console.log('chat id ', this.file['chat_id']);

        for (let key in data) {
          if (data.hasOwnProperty(key)) {

            let chat = data[key];
            // console.log("chat data", chat)

            if(chat['type'] === 'receipt' && chat['payload'] === this.file['chat_id']){
              arr.push(chat)

              if(chat['sentTo'] === 'admin'  && chat['read'] === false ){
                const updateData = {
                  'recipientId': this.admin.id,
                  'recipientName': this.admin.name,
                  'read': true
                }
                const upRef = ref(db, 'chats/' + this.driver.id + '/' + key)
                //update(upRef, updateData);
              }

            }


          }
        //
        //
        }

        arr.sort(function(a, b){return a['sentAt']-b['sentAt']})
        console.log("chat data",arr);
        this.chatMessagesData = arr;
        // console.log('chatMessagesData', this.chatMessagesData)
        this.scrollToBottom();

      });
    },

    sendMessage(){
      if(this.form.message === ""){
        return;
      }
      const db = getDatabase();

      const chatRef = ref(db, 'chats/' + this.driver.id);
      const newChatRef = push(chatRef);

      console.log('file on submit', this.file);

      const driverName = this.driver.name + " " + this.driver['other_names'];
      const item = chatItem(this.form.message, this.driver.id, driverName, 'receipt', this.file['chat_id'])

      set(newChatRef, item).then(() => {
        // Data saved successfully!
        this.form.message = ''
        this.scrollToBottom()
        this.updateDriverUser(item['message'], item['sentAt'])
      }).catch((error) => {
      // The write failed...
      });
    },

    formatTimestamp(value){
      return moment(value).calendar();
    },


  //  chats area
    setupDriverChat(driver) {

      const db = getDatabase();

      const userRef = ref(db, 'users/' + driver.id);

      const data =  chatUserItem(driver, 'receipt');

      // check if user ref has already not been created
      // if not, create a chat channel for user
      get(userRef).then((snapshot) => {

        console.log('setupDriverChat called', this.chatUser)

        if (!snapshot.exists()) {
          set(userRef, data).then(() => {
            this.chatUser = data;
          }).then(() => {
            this.searchMode = false
          })
        }else{
          this.updateAdminPendingMessages()
        }

      }).catch((error) => {
        console.error(error);
      });

    },

    updateAdminPendingMessages(newValue = 0){
      console.log('updateAdminPendingMessages called', this.chatUser)
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);
      const updateData = {
        'pendingReceiptMessagesForAdmin': newValue,
      }
      update(userRef, updateData);
    },

    updateDriverUser(lastMessage, lastMessageTime){
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);

      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {

          const map = snapshot.val();
          let pendingSupportMessagesForDriver = map['pendingReceiptMessagesForDriver'];
          pendingSupportMessagesForDriver = pendingSupportMessagesForDriver + 1;

          const updateData = {
            'lastMessage': lastMessage,
            'lastMessageTime': lastMessageTime,
            'pendingReceiptMessagesForDriver': pendingSupportMessagesForDriver,
          }

          update(userRef, updateData);

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

      this.updateAdminPendingMessages();

    },

  },
  mounted() {
    console.log('file', this.file)
    this.setupDriverChat(this.driver)
    this.fetchChats()
    this.scrollToBottom()
  }
}
</script>

<style scoped>

</style>